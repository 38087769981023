<template>
  <sign-page
    title-text="处分信息管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :tableActions="tableActions"
    table-size="large"
    :dealSearchParm="dealSearchParm"
    :dealFormData="dealFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  rewPunRequest as request,
} from '@/api'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  methods: {
    dealSearchParm () {
      return {
        type: 'pun'
      }
    },
    dealFormData (data) {
      data.type = 'pun'
      return data
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.workerList,
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '处分种类',
          selectDatas: this.$store.getters.selectDatas['pun_type_list'],
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '受处分期间',
          key: 'timeLong'
        },
        {
          type: 'input',
          label: '处分原因',
          key: 'reson'
        },
        {
          type: 'input',
          label: '批准单位名称',
          key: 'pzdw'
        },
        {
          type: 'datePicker',
          label: '批准时间',
          key: 'pzsj'
        },
        {
          type: 'input',
          label: '处分上报单位',
          key: 'sbdw'
        },
        {
          type: 'textarea',
          label: '处分原因简介',
          key: 'resonDetail'
        },
        {
          type: 'input',
          label: '解除原因',
          key: 'jcyy'
        },
        {
          type: 'datePicker',
          label: '解除时间',
          key: 'jcsj'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '身份证号',
        field: 'idNo',
        sort: true,
        width: 150
      },
      {
        title: '处分种类',
        field: 'title',
        sort: true
      },
      {
        title: '受处分期间',
        field: 'timeLong',
        sort: true
      },
      {
        title: '处分原因',
        field: 'reson',
        sort: true
      },
      {
        title: '批准单位名称',
        field: 'pzdw',
        sort: true
      },
      {
        title: '批准时间',
        field: 'pzsj',
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.pzsj ? rowData.pzsj.slice(0, 10) : '-')
        }
      },
      {
        title: '处分上报单位',
        field: 'sbdw',
        sort: true
      },
      {
        title: '处分原因简介',
        field: 'resonDetail',
        sort: true
      },
      {
        title: '解除原因',
        field: 'jcyy',
        sort: true
      },
      {
        title: '解除时间',
        field: 'jcsj',
        sort: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.jcsj ? rowData.jcsj.slice(0, 10) : '-')
        }
      }]
      return data
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
